<template>
  <div class="container page">
    <van-nav-bar
      :title="$t(`Page['填写收款卡']`)"
      class="nav-bar">
      <template #left>
        <van-icon
          name="arrow-left"
          color="#fff"
          @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{ $t(`Page['请输入您的收款卡信息']`) }}</div>
      <van-cell-group>
        <!-- <van-field
        v-model="bank"
        :label="$t(`Page['银行名称']`)"
        :placeholder="$t(`Page['请选择银行']`)" 
        @click="showSelectBanks()" /> -->
        <van-field
          v-model="bank"
          :label="$t(`Page['银行名称']`)"
          :placeholder="$t(`Page['请选择银行']`)" />
        <van-field
          v-model="bankid"
          :label="$t(`Page['银行卡号']`)"
          type="digit"
          :placeholder="$t(`Page['请输入真实银行卡号']`)" />
      </van-cell-group>
      <p
        >{{
          $t(
            `Page['尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款']`,
          )
        }}
      </p>
    </div>
    <div class="bindCard">
      <div
        class="btn_card"
        @click="bindCard()">
        {{ $t(`Page['提交']`) }}
      </div>
    </div>
    <van-popup
      v-model="showBank"
      round
      position="bottom"
      :style="{ height: '35%' }">
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        banks: [],
        showBank: false,
        userInfo: {},
        bankid: "",
        bank: "",
      };
    },
    methods: {
      back() {
        return window.history.back();
      },
      bindCard() {
        if (this.userInfo.bankid) {
          this.$toast(this.$t(`Page['请输入真实银行卡号']`));
          return true;
        }
        if (this.bank === "" || this.bank === null || this.bank === undefined) {
          this.$toast(this.$t(`Page['请选择银行']`));
          return false;
        }
        this.$http({
          method: "post",
          data: { bankid: this.bankid, bank: this.bank },
          url: "user_set_bank",
        }).then((res) => {
          if (res.code === 200) {
            this.$router.push({ path: "/Mine" });
            this.$toast(res.msg);
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
      showSelectBanks() {
        this.showBank = true;
      },
      getUserInfo() {
        this.$http({
          method: "get",
          url: "user_info",
        }).then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            this.name = res.data.name;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
      getBankList() {
        this.$http({
          method: "get",
          url: "sys_get_banks",
        }).then((res) => {
          if (res.code === 200) {
            this.banks = res.data;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
      onConfirm(value) {
        this.bank = value.text;
        this.showBank = false;
      },
      onCancel() {
        this.showBank = false;
      },
      getUserBankInfo() {
        this.$http({
          method: "get",
          url: "user_get_bank",
        }).then((res) => {
          if (res.code === 200) {
            if (res.data.is_bank) {
              this.is_bind = true;
            } else {
              this.is_bind = false;
            }
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
    },
    created() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.getUserInfo();
        this.getBankList();
        this.getUserBankInfo();
      }
    },
  };
</script>

<style lang="less" scoped>
  .van-cell {
    font-size: 14px;
    line-height: 80px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 3px;
  }
  .bankbox {
    padding: 15px;
    color: #000;
    background-color: #fff;
  }
  .bankbox .title {
    padding: 8px 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
  }
  .main-box {
    background: #fff;
  }
  .main-box .label {
    padding: 14px;
    font-size: 35px;
    color: #797878;
  }
  ::v-deep .van-picker__toolbar {
    height: 50px;
  }
  ::v-deep .van-picker__cancel,
  .van-picker__confirm {
    padding: 0 20px;
    font-size: 20px;
  }
  ::v-deep .van-picker-column {
    font-size: 30px;
  }
  .main-box p {
    padding: 20px;
    margin: 0px;
    font-size: 30px;
    color: #ee0a24;
  }
  .bindCard {
    margin: 20px 280px;
    padding: 20px 0;
    height: 80px;
    line-height: 2.1rem;
    border-radius: 50px;
    color: #fff;
    font-size: 30px;
    font-weight: bolder;
    border: none;
    background: linear-gradient(90deg, #3793ff, #0017e4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
